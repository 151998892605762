import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { ShipList } from "../../DataStore/shipList";
import "react-awesome-slider/dist/styles.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { HiArrowLongRight } from "react-icons/hi2";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { useState } from "react";
import empty from "../../assets/img/others/empty.png";
import { TfiLayoutLineSolid } from "react-icons/tfi";

function AllShips() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const filteredShips = ShipList?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const AllShipsScript = `
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": "All Ships",
    "description": "Explore our fleet of ships with a variety of cruise experiences.",
    "itemListElement": [
      ${ShipList.map((ship, index) => {
        return `
          {
            "@type": "ListItem",
            "position": ${index + 1},
            "item": {
              "@type": "Ship",
              "name": "${ship.name}",
              "url": "https://www.ncl-me.com/ship-details/${ship.urlName}"
            }
          }
        `;
      }).join(",\n")}
    ]
  }
`;

  // Custom Previous Button component
  const CustomPrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={<IoIosArrowBack className="font-black mh-32" />}
      className="custom-prev-button"
      onClick={onClick}
    />
  );

  // Custom Next Button component
  const CustomNextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={<IoIosArrowForward className="font-black mh-32" />}
      className="custom-next-button"
      onClick={onClick}
    />
  );

  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton onClick={() => {}} />,
    nextArrow: <CustomNextButton onClick={() => {}} />,
    centerMode: true,
    centerPadding: "5%",
    autoplay: true,
  };

  return (
    <div className="all-ships">
      <Helmet>
        <title>Norwegian Cruises - Learn About Our Cruise Ships</title>
        <meta
          name="description"
          content="Explore the impressive fleet of Norwegian Cruises. Learn about the ships with detailed ship facts, deck plans, and more."
        />
        <link rel="canonical" href="https://www.ncl-me.com/all-ships" />
        <script type="application/ld+json">{AllShipsScript}</script>
        <meta property="og:title" content="Norwegian Cruises Ships" />
        <meta property="og:image" content="https://www.ncl-me.com/ships.jpg" />
        <meta
          property="og:description"
          content="Explore the impressive fleet of Norwegian Cruises. Learn about the ships with detailed ship facts, deck plans, and more."
        />
        <meta property="og:url" content="https://www.ncl-me.com/all-ships" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="breadcrumb-div full-height">
        <div
          className="full-height flex center-start"
          style={{ marginLeft: 32 }}
        >
          <h1 className="font-faded-black basic-texts font-regular">
            <Link to="/" className="black-link">
              Home
            </Link>{" "}
            &nbsp;
            <TfiLayoutLineSolid />
            &nbsp; Our Ships
          </h1>
        </div>
      </div>
      <div className="custom-carousel-container mt-8">
        <Slider {...settings}>
          {ShipList?.map((item: any, index: number) => (
            <div key={index}>
              <div
                className="overlay-container-ships pointer"
                onClick={() => {
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 100);
                  navigate(`/ship-details/${item?.urlName}`);
                }}
              >
                <img
                  src={item?.imagepathWide}
                  alt={item?.name + " destination slide"}
                  className="image-slide"
                />
                <div className="overlay-content-ships flex start-center p-24 flex-column">
                  <h3 className="font-white large-heading font-bold ">
                    {item?.name}
                    <div className="ships-custom-divider" />
                  </h3>
                  <h5 className="font-white description-text font-regular mt-16">
                    More About {item?.name}{" "}
                    <HiArrowLongRight style={{ fontSize: "32px" }} />
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="bg-white column-padding-h column-padding-b all-ships-padding-t">
        <Container fluid>
          <div className="flex flex-column mb-32">
            <Fade duration={2000} triggerOnce>
              {" "}
              <h2 className="bold-700 sub-heading font-medium">Our Ships</h2>
            </Fade>
            <p
              className="mt-16 justified description-text font-regular"
              style={window.innerWidth <= 554 ? { marginBottom: "32px" } : {}}
            >
              Each Norwegian vessel stands as its own unique destination,
              boasting an array of amenities, exceptional culinary offerings,
              and engaging activities. Explore our deck plans, discover detailed
              stateroom information, explore our diverse dining options, and
              more for every ship in our fleet. Follow the links below to
              uncover the distinctive offerings that define the Norwegian
              experience.
            </p>
          </div>
          <div className="full-width flex center">
            <Input
              size="large"
              placeholder="Search our ships"
              prefix={<FiSearch style={{ marginRight: "10px" }} />}
              className="mb-32"
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ width: "80%", borderRadius: "0px" }}
            />
          </div>
          <Row>
            {filteredShips?.length > 0 ? (
              filteredShips?.map((item: any) => {
                return (
                  <Col sm={12} md={6} lg={4} className="mb-32">
                    <div
                      className="pointer"
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                        navigate(`/ship-details/${item?.urlName}`);
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={item?.imagepath}
                          alt="ship"
                          style={{ width: "100%", height: "auto" }}
                        />
                        <div
                          style={{ position: "absolute", bottom: 0 }}
                          className="full-width"
                        >
                          <div className="p-24 flex-column ship-info-div ">
                            <h3 className="ship-name bold-600 font-white description-text font-regular">
                              {item?.name}
                              <div
                                className="ships-custom-divider-cmp mb-16"
                                style={{ marginTop: "8px" }}
                              />
                            </h3>
                            <Row className="mt-16">
                            <Col className="flex center flex-column">
                              <h5 className="font-14 font-white opacity80">
                                Guests
                              </h5>
                              <h5 className="font-14 font-white opacity">
                                {item?.shipInfo?.guestCapacity}
                              </h5>
                            </Col>
                            <Col className="flex center flex-column">
                              <h5 className="font-14 font-white opacity80">
                                Tonnage
                              </h5>
                              <h5 className="font-14 font-white opacity">
                                {item?.shipInfo?.Tonnage}
                              </h5>
                            </Col>
                            <Col className="flex center flex-column">
                              <h5 className="font-14 font-white opacity80">
                                {item?.shipInfo?.cruiseInauguralText}
                              </h5>
                              <h5 className="font-14 font-white opacity">
                                {item?.shipInfo?.cruiseInaugural}
                              </h5>
                            </Col>
                            </Row>
                            {/* 
                        <h3 className="font-white basic-texts font-regular bold-500">
                          Learn More <FaLongArrowAltRight className="mh-8" />
                        </h3> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className="flex center flex-column">
                <img src={empty} alt="empty-result" height={100} />
                <h4 className="description-text font-faded-black">
                  No Result found..!
                </h4>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AllShips;
