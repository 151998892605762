import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import {
  DestinationList
} from "../../DataStore/destinationList";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "antd";

function Destinations() {
  const navigate = useNavigate();

  return (
    <>
      <div className="destinations-component column-padding-h column-padding-b column-padding-t">
        <Container fluid>
          <div className="flex flex-column">
            <h2 className="sub-heading font-medium bold-800 mb-16">
              Popular Destinations
            </h2>
            <p className="description-text font-medium font-faded-black mb-32">
              Explore our most popular destinations
            </p>
          </div>
          <Row>
            {DestinationList?.slice(0, 4)?.map((item: any, key: number) => {
              return (
                <Col
                  sm={12}
                  md={6}
                  lg={3}
                  className="mb-32 pointer"
                  key={key}
                  onClick={() => {
                    window.open(`${item?.moreInfo}`, "_self");
                  }}
                >
                  <div className="">
                    <div className="destination-popular-image">
                      <img src={item?.imagepath} alt="Destinations" />
                    </div>

                    <h3 className="description-text font-regular bold-600 mt-16">
                      {item?.to}
                    </h3>
                    <h4 className="basic-texts font-regular bold-600 mt-16">
                      {item?.daycount}
                    </h4>
                    <h4 className="basic-texts font-regular bold-600 mt-16">
                      Price starting from{" "}
                      <span className="font-primary">USD {item?.price}</span>
                    </h4>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="flex start">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 100);
                navigate("/all-destinations");
              }}
              className="card-button btn-primary btn-small basic-texts font-regular font-white ml-3"
            >
              View All Destinations
            </Button>
          </div>
        </Container>
      </div>
      {/* <div className="destinations-component secondary-bg">
        <Container fluid>
          <Row>
            <Col lg={5}>
              <div className="flex start-center flex-column full-height column-padding">
                <h3 className="description-text font-regular bold-600 font-black mb-16">
                  DESTINATIONS
                </h3>
                <p className="description-text font-regular bold-400 font-faded-black mb-32">
                  Come aboard Norwegian Cruise Line to Alaska and feel what it's
                  like when you let the outside in. Browse through our Alaskan
                  cruises and find the best cruise deals designed to make your
                  cruise experience unforgettable. With 7 cruise ships sailing
                  from 6 departure ports, including land and sea packages on our
                  Alaska Cruise Tours, there's something for everyone to enjoy.
                </p>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="card-button btn-primary btn-small basic-texts font-regular font-white"
                >
                  More Destinations
                </Button>
              </div>
            </Col>
            <Col lg={7} className="column-padding">
              <Slider {...settings}>
                {destinationNarrow?.map((item: any, index: number) => (
                  <div key={index}>
                    <div
                      className={`overlay-container-destinations-cmp pointer ${
                        index === 0 ? "slick-current" : ""
                      }`}
                      style={{
                        backgroundImage: `linear-gradient( to bottom , rgba(0, 0, 0,0) , rgba(0, 0, 0, 0.7)),url(${item?.imagepath})`,
                        backgroundPosition: "center",
                      }}
                      onClick={() => {
                        window.open(`${item?.moreInfo}`, "_self");
                      }}
                    ></div>
                    {index === currentSlide && <h2>{item?.name}</h2>}
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
}

export default Destinations;
