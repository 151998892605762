import { Divider } from "antd";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/logos/logo-ncl-black.png";
import "./styles.scss";
import { Drawer } from "antd";
import { IoIosMenu } from "react-icons/io";
import { ImMobile } from "react-icons/im";

function HeaderComponent() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <header className="common-header bg-white">
      <div
        className="flex space-between full-height"
      >
        <RouterLink
          to="/"
          onClick={() => {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
          className="flex center"
        >
          <div className="header-brand">
            <img src={logo} alt="Norwegian Cruises Logo" />
          </div>
        </RouterLink>
        <div className="flex center">
          <a
            href="https://book.ncl-me.com/"
            className={"custom-link font-regular font-black-header basic-texts nav-menu-item"}
            target="_self"
            rel="noopener noreferrer"
          >
            Plan a Cruise
          </a>

          <RouterLink
            to={"/all-destinations"}
            className={"custom-link font-regular font-black-header basic-texts nav-menu-item"}
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            {" "}
            Destinations
          </RouterLink>
          <RouterLink
            to={"/all-ships"}
            className={"custom-link font-regular font-black-header basic-texts nav-menu-item"}
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            {" "}
            Our Ships
          </RouterLink>
          <Divider
            type="vertical"
            style={{ backgroundColor: "black", width: "2px", height: "18px" }}
            className="contact-details opacity80"
          />
          <ImMobile
            style={{ fontSize: "1.1rem", marginLeft: "24px" }}
            className="contact-details font-black-header"
          />
          <p
            className={
              "contact-text font-black-header basic-texts font-regular contact-details"
            }
            style={{ marginLeft: "5px", marginRight: "16px" }}
          >
            +971 4 299 9227
          </p>
          <IoIosMenu className="bar font-black-header pointer" onClick={showDrawer} />
        </div>
      </div>
      <Drawer placement="right" onClose={onClose} open={open} width={"250px"}>
        <div
          style={{
            cursor: "pointer",
            padding: "16px 0px",
          }}
          onClick={() => {
            navigate("/");
            onClose();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <h5
            style={{
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              color: "#0054a0",
              justifyContent: "flex-start",
              fontSize: "16px",
            }}
          >
            Home
          </h5>
        </div>
        <Divider style={{ margin: 0 }} />
        <div
          style={{
            cursor: "pointer",
            padding: "16px 0px",
          }}
          onClick={() => {
            onClose();
          }}
        >
          <a
            href="https://book.ncl-me.com/"
            style={{
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              textDecoration: "none",
              color: "#0054a0",
              fontSize: "16px",
            }}
            className="basic-texts font-regular"
            target="_self"
            rel="noopener noreferrer"
          >
            Plan a Cruise
          </a>
        </div>
        <Divider style={{ margin: 0 }} />
        <div
          style={{
            cursor: "pointer",
            padding: "16px 0px",
          }}
          onClick={() => {
            navigate("/all-destinations");
            onClose();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <h5
            style={{
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              color: "#0054a0",
              justifyContent: "flex-start",
              fontSize: "16px",
            }}
          >
            Destinations
          </h5>
        </div>
        <Divider style={{ margin: 0 }} />
        <div
          style={{
            cursor: "pointer",
            padding: "16px 0px",
          }}
          onClick={() => {
            navigate("/all-ships");
            onClose();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <h5
            style={{
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              color: "#0054a0",
              justifyContent: "flex-start",
              fontSize: "16px",
            }}
          >
            Our Ships
          </h5>
        </div>
        <Divider style={{ margin: 0 }} />
        <div
          style={{
            cursor: "pointer",
            padding: "16px 0px",
          }}
          onClick={() => {
            navigate("/contact");
            onClose();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <h5
            style={{
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              color: "#0054a0",
              justifyContent: "flex-start",
              fontSize: "16px",
            }}
          >
            Contact
          </h5>
        </div>
      </Drawer>
    </header>
  );
}

export default HeaderComponent;
