import React, { useRef } from "react";
import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Divider, Form } from "antd";
import { Link } from "react-router-dom";
import "react-awesome-slider/dist/styles.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import TextField from "@mui/material/TextField";
import { MdMyLocation } from "react-icons/md";
import { notification } from "antd";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import { FormInstance } from "antd";
import { TfiLayoutLineSolid } from "react-icons/tfi";

type NotificationType = "success" | "info" | "warning" | "error";

function Contact(props: any) {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Welcome Aboard!",
      description:
        "Thank you for joining our cruise community! Prepare to receive exclusive booking alerts and exciting offers. Welcome aboard!",
    });
  };

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "Invalid Email!",
    },
  };
  /* eslint-enable no-template-curly-in-string */
  const formRef = useRef<FormInstance>(null);

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    try {
      const formValues = form.getFieldsValue();

      const formElement = document.createElement("form");
      Object.entries(formValues).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.name = key;
        input.value = String(value);
        formElement.appendChild(input);
      });

      const result = await emailjs.sendForm(
        "service_l7f4pft",
        "template_bm9tyme",
        formElement,
        "zjf0ASoEwdw4aV6xq"
      );

      console.log(result.text);
      openNotificationWithIcon("success");
      form.resetFields();
    } catch (error) {
      console.error(error);
      openNotificationWithIcon("error");
      form.resetFields();
    }
  };

  const handleButtonClick = () => {
    const officeAddress = "Cruise Master LLC, Oud Metha, Dubai,UAE";

    const encodedAddress = encodeURIComponent(officeAddress);

    const mapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;

    window.open(mapsUrl, "_blank");
  };

  return (
    <div className="contact">
      <Helmet>
        <title>
          Contact Norwegian Cruise Line Agent in the Middle East
        </title>
        <meta
          name="description"
          content="Contact Norwegian Cruises - Your trusted sales and booking agent in the Middle East. Reach out for inquiries, bookings, and assistance with planning your cruise."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "Norwegian Cruises",
              "description": "Contact Norwegian Cruises for inquiries, bookings, and assistance with planning your cruise."
            }
          `}
        </script>
        <link
          rel="canonical"
          href="https://www.ncl-me.com/contact"
        />
        <meta
          property="og:title"
          content="Contact Norwegian Cruises - Sales and Booking Agent in the Middle East"
        />
        <meta
          property="og:image"
          content="https://www.ncl-me.com/favicon.png"
        />
        <meta
          property="og:description"
          content="Contact Norwegian Cruises - Your trusted sales and booking agent in the Middle East. Reach out for inquiries, bookings, and assistance with planning your cruise."
        />
        <meta property="og:url" content="https://www.ncl-me.com/contact" />
        <meta property="og:type" content="website" />
      </Helmet>
      {contextHolder}
      <div className="flex flex-column" style={{ marginBottom: "64px" }}>
        <div className="breadcrumb-div full-height">
          <div className="full-height flex center-start" style={{marginLeft:32}}>
            <h1 className="font-faded-black basic-texts font-regular">
              <Link to="/" className="black-link">
                Home
              </Link>{" "}
              &nbsp;<TfiLayoutLineSolid/>&nbsp; Contact Us
            </h1>
          </div>
        </div>
        <Container>
          <h2 className="font-white mt-32 text-align-center description-text font-regular">
            Connect with us effortlessly. Have questions or feedback? We're here
            to help. Reach out and let's make your cruise experience
            exceptional!
          </h2>
        </Container>
      </div>

      <Container>
        <Row style={{ minHeight: "400px" }}>
          <Col sm={12} md={4} className="responsive-column-padding">
            <div className="contact-left-col full-height">
              <h3 className="font-white bold-600">Get In Touch</h3>
              <Divider className="bg-white" />
              <div>
                <p className="bold-600 mb-16 font-white">Office Address</p>
                <div className="flex">
                  <div style={{ width: "20px" }}>
                    <FaLocationDot
                      style={{ marginBottom: "5px" }}
                      className="font-white"
                    />
                  </div>
                  <div>
                    <p
                      className="bold-500 basic-texts font-regular font-white"
                      style={{ marginLeft: "8px", marginBottom: "0px" }}
                    >
                      Cruise Master LLC,
                    </p>

                    <p
                      className="bold-500 mb-16 basic-texts font-regular font-white"
                      style={{ marginLeft: "8px" }}
                    >
                      Suite 603, Office Court Bldg - Oud Metha - Dubai
                    </p>
                  </div>
                </div>
                <Button
                  type="default"
                  className="no-radius mt-16"
                  onClick={handleButtonClick}
                  style={{ backgroundColor: "transparent" }}
                >
                  <p className="bold-500 font-white">
                    <MdMyLocation
                      style={{ marginBottom: "3px", marginRight: "4px" }}
                    />
                    Directions
                  </p>
                </Button>
                <Divider className="bg-white" />

                <p className="bold-600 mb-16 font-white">Contact Info</p>
                <div className="flex">
                  <div style={{ width: "20px" }}>
                    <FaPhone
                      style={{ marginBottom: "5px" }}
                      className="font-white"
                    />
                  </div>
                  <p
                    className="bold-500 mb-16 basic-texts font-regular font-white"
                    style={{ marginLeft: "8px" }}
                  >
                    +971 4 299 9227
                  </p>
                </div>

                <div className="flex">
                  <div style={{ width: "20px" }}>
                    <IoMdMail
                      style={{ marginBottom: "5px" }}
                      className="font-white"
                    />
                  </div>
                  <p
                    className="bold-500 mb-16 basic-texts font-regular font-white"
                    style={{ marginLeft: "8px" }}
                  >
                    book@ncl-me.com
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={8} className="responsive-column-padding">
            <div className="contact-form-card full-height">
              <h3 className="bold-600 mb-32 description-text font-regular">
                Send Us A Message
              </h3>
              <Form
                name="contactUsForm"
                onFinish={onFinish}
                layout="vertical"
                form={form}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <Row className="mb-16">
                  <Col>
                    <Form.Item
                      name="user_name"
                      rules={[
                        { required: true, message: "Please enter your name" },
                      ]}
                    >
                      <TextField
                        id="standard-basic"
                        label="Name"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="user_email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col>
                    <Form.Item name="phone">
                      <TextField
                        id="standard-basic"
                        label="Phone"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="subject">
                      <TextField
                        id="standard-basic"
                        label="Subject"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item
                    name="message"
                    rules={[
                      { required: true, message: "Please enter your message" },
                    ]}
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Message"
                      multiline
                      rows="4"
                      variant="standard"
                      name="message"
                      fullWidth
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item className="flex end mt-32">
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                      className="card-button btn-small basic-texts font-regular"
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Col>
          {/* <Col sm={12} md={3} className="ph-0">
            <iframe
              src="https://maps.google.com/?width=100%&height=600&hl=en&coord=25.244880648515984,55.31515567978187
              &q=Cruise%Master%LLC%2C%Dubai&ie=UTF8&z=19&iwloc=B&output=embed"
              width="100%"
              height="500px"
              style={{ maxWidth: "100%" }}
            />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
