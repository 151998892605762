//Ships
import BASE_URL from "../Config/apiConfig";

export const ShipList = [
  {
    shipid: 19,
    name: "Norwegian Viva",
    urlName: "norwegian-viva",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-viva?ship=15159",
    details:
      "Embrace a life well-lived on the all-new Norwegian Viva, the latest addition to a groundbreaking class of ships crafted for those who savor every moment. Designed to bring you closer to the ocean, the ship offers expansive accommodations and unparalleled service. Whether you're strolling along Ocean Boulevard, relaxing on Infinity Beach, or indulging in culinary excellence at Indulge Food Hall, Norwegian Viva ensures an unobstructed connection between you and the sea. Join us for a new and unforgettable experience where living it up takes center stage.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianVivaLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/viva_wide.jpg`,
    videopath: "https://www.youtube.com/embed/SDg_fTEbjSA?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "142,500",
      guestCapacity: "3,099",
      cruiseInaugural: "2023",
      cruiseInauguralText: "Year Built",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,506",
    },
  },
  {
    shipid: 14,
    name: "Norwegian Prima",
    urlName: "norwegian-prima",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-prima?ship=15089",
    details:
      "For the trailblazers, tastemakers, and seekers of the extraordinary, Norwegian Prima welcomes you. As the first in a new class of ships, it's designed to elevate every expectation. With the most outdoor deck space of any new cruise ship, spacious accommodations, and impeccable service, it's crafted for those who appreciate the finer things. Take a 360° seaside stroll along Ocean Boulevard, savor a delicious journey at the Indulge Food Hall, and relax in our infinity pools. Discover a new era of sea travel aboard Norwegian Prima.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianPrimaLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/prima_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/yRjnwy0uyn0?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "143,535",
      guestCapacity: "3,099",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Year Built",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,506",
    },
  },
  {
    shipid: 1,
    name: "Norwegian Aqua",
    urlName: "norwegian-aqua",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-aqua?ship=15329",
    details:
      "Meet Norwegian Aqua, the latest evolution of our innovative Prima Class. Elevate every part of your holiday with more expansive outdoor space and unobstructed scenic views. Enjoy more first-of-its-kind experiences like the all-new Aqua Slidecoaster. And feel right at home with a greater variety of spacious suites. Let each new experience flow into the next on a ship as inspiring as the ocean itself. This is Norwegian Aqua™. Make New Waves — starting 2025.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianAquaLarge.jpg`,
    imagepathWide: `${BASE_URL}/img/all_ships/aqua_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/sp4KvinXe48?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "156,300",
      guestCapacity: "3,571",
      cruiseInaugural: "2025",
      cruiseInauguralText: "Year Built",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "-",
    },
  },
  {
    shipid: 2,
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-bliss?ship=13670",
    name: "Norwegian Bliss",
    urlName: "norwegian-bliss",
    details:
      "Experience the thrill of exploring Alaska's wilderness and the tranquility of paradise in The Caribbean aboard Norwegian Bliss. This custom-built ship boasts a revolutionary Observation Lounge, allowing you to savor breathtaking moments, from bald eagles over glaciers to dolphins in turquoise waters. Enjoy top-notch dining, entertainment, and amenities against the backdrop of unparalleled natural beauty. Whether opting for a tropical escape or a wild adventure, Norwegian Bliss promises the best at sea. One word encapsulates the experience on one of Norwegian's grandest ships: Bliss",
    imagepath: `${BASE_URL}/img/all_ships/norwegianBlissLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/bliss_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/OQHgFhiOTDo?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "168,028",
      guestCapacity: "4,004",
      cruiseInaugural: "2021",
      cruiseInauguralText: "Refurbished",
      length: "333m",
      height: "41m",
      guestCabins: "",
      numberofDecks: "",
      crew: "1,716",
    },
  },
  {
    shipid: 3,
    name: "Norwegian Breakaway",
    urlName: "norwegian-breakaway",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-breakaway?ship=13507",
    details:
      "Embark on your ultimate vacation with Norwegian Breakaway. Whether it's a Caribbean adventure, a historical Mediterranean cruise, or a tranquil Transatlantic voyage, this cruise ship has it all. Voted Best Cruise Ship for Families by USA Today, it caters to everyone's tastes. Immerse yourself in the sea breeze along The Waterfront, a quarter-mile oceanfront promenade with scenic views and diverse dining options, including three new seafood venues. Norwegian Breakaway ensures an extraordinary seafaring experience with its magnificent amenities.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianBreakAwayLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/break_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/2roP5H6MONY?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "145,655",
      guestCapacity: "3,963 ",
      cruiseInaugural: "2020",
      cruiseInauguralText: "Refurbished",
      length: "52m",
      height: "326m",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,657",
    },
  },
  {
    shipid: 4,
    name: "Norwegian Dawn",
    urlName: "norwegian-dawn",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-dawn?ship=48",
    details:
      "Indulge in the ultimate freedom aboard Norwegian Dawn for your cruise holiday. Explore charming European destinations, sail across the Atlantic, or embark on an Extraordinary Journey. Onboard, savor the flavors at Los Lobos Cantina, enjoy wine tasting at The Cellars, and try your luck at Dawn Casino. With a variety of spacious accommodations to fit any budget, Norwegian Dawn invites you to discover dreamy destinations worldwide.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianDawnLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/dawn_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "92,250",
      guestCapacity: "2,340",
      cruiseInaugural: "2021",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,032",
    },
  },
  {
    shipid: 5,
    name: "Norwegian Encore",
    urlName: "norwegian-encore",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-encore?ship=14090",
    details:
      "Relive perfect moments on Norwegian Encore, designed for endless excitement! Island-hop in the Caribbean from Miami or cruise to Alaska from Seattle. Race on the largest sea track, enjoy virtual reality at Galaxy Pavilion, and rock out at The Choir Of Man. Indulge in diverse dining, including Onda by Scarpetta. Norwegian Encore offers non-stop memorable experiences – because once is never enough.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianEncoreLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/encore_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/o6nYbaMdM-Y?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "169,116",
      guestCapacity: "3,998",
      cruiseInaugural: "2019",
      cruiseInauguralText: "Year Built",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,735",
    },
  },
  {
    shipid: 6,
    name: "Norwegian Epic",
    urlName: "norwegian-epic",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-epic?ship=13236",
    details:
      "Discover the Mediterranean's history, unwind on the Transatlantic, or bask in Caribbean breezes – Norwegian Epic promises to impress. Voted Best Cruise Ship Entertainment by Frommer's, it features the sensational Burn the Floor. With award-winning performers, innovative accommodations like Studios for solo travelers, and luxurious privacy in The Haven, Norwegian Epic redefines Freestyle Cruising on a grand scale. Indulge in diverse dining options for a truly exceptional experience.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianEpicLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/epic_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/NmPl8pW6tcw?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "155,873",
      guestCapacity: "4,100",
      cruiseInaugural: "2020",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,724",
    },
  },
  {
    shipid: 7,
    name: "Norwegian Escape",
    urlName: "norwegian-escape",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-escape?ship=13669",
    details:
      "Sail on the exhilarating Norwegian Escape, a ship that promises unforgettable adventures. Depart for The Caribbean from Miami and Orlando, Canada & New England from New York, or the Mediterranean from Rome. Embrace every desire as you chase sunsets on The Waterfront, build sandcastles in Bermuda, and savor Napa's finest blends at The Cellars, A Michael Mondavi Family Wine Bar. With unique hand-crafted cocktails and a world of freedom, Norwegian Escape invites you to create unforgettable holiday memories.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianEscapeLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/escape_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/TGztJFajbwE?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "164,998",
      guestCapacity: "4,266",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,733",
    },
  },
  {
    shipid: 8,
    name: "Norwegian Gem",
    urlName: "norwegian-gem",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-gem?ship=1100",
    details:
      "This sparkling cruise ship is the perfect choice for year-round cruises in style. Sail to the The Caribbean, Bermuda, or Bahamas and toss your timetable to the breeze. Or stroll along postcard-perfect scenery in the Mediterranean. With tonnes of dining choices and no set dining times, Norwegian Gem has it all. Chill out by the pool, get lucky in the casino, unwind at the spa, and make the kids happy with spaces built with them in mind. Accommodations range from the luxurious multi-room or romantic suites to spacious and affordable staterooms.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianGemLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/gem_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "93,530",
      guestCapacity: "2,394",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,070",
    },
  },
  {
    shipid: 9,
    name: "Norwegian Getaway",
    urlName: "norwegian-getaway",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-getaway?ship=13608",
    details:
      "Norwegian Getaway blends the best of Norwegian's amenities with unforgettable destinations. Walk The Waterfront, an open-air promenade connecting guests with the ocean. Enjoy 20 dining options, five water slides, and a sports complex. Experience Broadway's Million Dollar Quartet. Whether exploring Caribbean beaches, building pink sandcastles in Bermuda, delving into European history, or relaxing on a Transatlantic cruise, Miami’s Ultimate Ship is your ultimate getaway.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianGetawayLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/getaway_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/DCT_JibbZSc?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "145,655",
      guestCapacity: "3,963",
      cruiseInaugural: "2020",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,646",
    },
  },
  {
    shipid: 10,
    name: "Norwegian Jade",
    urlName: "norwegian-jade",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-jade?ship=1159",
    details:
      "Embark on your dream holiday aboard Norwegian Jade. Cruise the Mediterranean, exploring castles, cathedrals, and romantic islands. Opt for uniquely designed Extraordinary Journeys for immersive experiences. Enjoy entertainment day and night with nightclubs, award-winning restaurants, a grand casino, swimming pools, and a pampering spa. With tons of fun for kids, bring the whole family and experience the adventure of a lifetime. No wonder we've won the World Travel Award for 'Europe's Leading Cruise Line' 13 years in a row.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianJadeLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/jade_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "93,558",
      guestCapacity: "2,402",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,037",
    },
  },
  {
    shipid: 11,
    name: "Norwegian Jewel",
    urlName: "norwegian-jewel",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-jewel?ship=95",
    details:
      "Named after our Jewel Class cruise ships, Norwegian Jewel truly has it all. With spacious suites and staterooms for every style, this ship offers unrivaled accommodations. Experience excitement with a variety of dining options, vibrant bars, and nightclubs. Venture into the wild on an Alaska Cruise, go from scenic to city views on an Asia Cruise, toss your timetable to the breeze on a Caribbean cruise, or embark on an Extraordinary Journey for unique and immersive itineraries. Whichever destination you choose, get ready for the adventure of a lifetime!",
    videopath:
      "https://www.youtube.com/embed/DY21T8MCtAk?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    imagepath: `${BASE_URL}/img/all_ships/norwegianJewelLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/jewel_wide.jpg`,
    pdfurl: null,
    shipInfo: {
      Tonnage: "93,502",
      guestCapacity: "2,376",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,069",
    },
  },
  {
    shipid: 12,
    name: "Norwegian Joy",
    urlName: "norwegian-joy",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-joy?ship=13867",
    details:
      "Find your happy place on board Norwegian Joy when she cruises from the Miami to Panama Canal. Or join her in The Caribbean, Bahamas, and Bermuda from Miami and New York. Enjoy a rush like no other as you race in the middle of the ocean on a thrilling two–level race track. Be pampered in luxury in our spacious and beautifully appointed accommodations. Enjoy holiday experiences that will delight your senses and calm your soul on Norwegian Joy.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianJoyLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/joy_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "167,725",
      guestCapacity: "3,776",
      cruiseInaugural: "2024",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,821",
    },
  },
  {
    shipid: 13,
    name: "Norwegian Pearl",
    urlName: "norwegian-pearl",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-pearl?ship=1097",
    details:
      "Cruise to the breezy islands of The Caribbean, or simply sit back and sail through the world's greatest shortcut on a Panama Canal cruise. Whatever you choose to do, we invite you to experience the wonders of getting there aboard Norwegian Pearl. Her 16 chic dining options, 14 bars and lounges, dazzling casino, tranquil spa, and spacious Garden Villas are just a few things that make this Jewel Class cruise ship a destination of her own.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianPearlLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/pearl_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "93,530",
      guestCapacity: "2,394",
      cruiseInaugural: "2021",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,072",
    },
  },
  {
    shipid: 15,
    name: "Norwegian Sky",
    urlName: "norwegian-sky",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-sky?ship=1179",
    details:
      "From the moment you step aboard, you'll see that Norwegian Sky, refurbished from bow to stern in 2022, offers something for everyone. On our Bahamas cruises your options are nearly limitless with over 10 dining options, a wide variety of bars and lounges, an onboard spa, and a cruise casino that has all of your favourite games and slots. The popular Norwegian Sky offers 3- and 4-day cruises to the Bahamas from Miami. And, if you decide to bring the kids, we'll keep them entertained all day, whilst you lounge by the pool, play in the casino, or enjoy dinner at one of many restaurants.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianSkyLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/sky_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/qbLc84Rmf1o?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "77,104",
      guestCapacity: "2,004",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "899",
    },
  },
  {
    shipid: 16,
    name: "Norwegian Spirit",
    urlName: "norwegian-spirit",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-spirit?ship=94",
    details:
      "Prepare for a journey of laid-back luxury on the revamped Norwegian Spirit, meticulously modernized for the adult cruiser. This extensive renovation, the most comprehensive in our fleet, touches every aspect of the ship. From redesigned staterooms and lounges to elevated dining experiences, including the new fine Italian restaurant Onda by Scarpetta. Starting December 2022, Norwegian Spirit will embark on its maiden voyages to Australia and New Zealand. Please be aware that Norwegian Spirit does not offer Youth Programmes.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianSpiritLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/spirit_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "75,904",
      guestCapacity: "2,032",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "904",
    },
  },
  {
    shipid: 17,
    name: "Norwegian Star",
    urlName: "norwegian-star",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-star?ship=52",
    details:
      "Recently refurbished as part of The Norwegian Edge programme, Norwegian Star brings freedom and flexibility to cruises spanning the Mediterranean, Northern Europe, South America, and Transatlantic. Onboard, Norwegian Star features a large delicious dining options, bars and lounges, a sprawling spa, an always-exciting casino with VIP area, plus tonnes of fun for kids of every age.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianStarLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/star_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/_-b6p6wdy1U?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "91,740",
      guestCapacity: "2,348",
      cruiseInaugural: "2021",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "1,031",
    },
  },
  {
    shipid: 18,
    name: "Norwegian Sun",
    urlName: "norwegian-sun",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-norwegian-sun?ship=53",
    details:
      "Where in the world do you want to cruise? Chances are the recently refurbished Norwegian Sun can take you there with cruises into the frozen wilderness of Alaska or exotic, immersive experiences with our Extraordinary Journeys. Whilst you're becoming a world traveller, you won't have to worry about what to do or where to go, as there are plenty of dining options, a wide variety of bars and lounges, the relaxing Mandara Spa, and the always-exciting Sun Casino.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianSunLarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/sun_wide.jpg`,
    videopath:
      "https://www.youtube.com/embed/hhguxjJWg30?autoplay=0&mute=0&rel=0&controls=1&enablejsapi=1",
    pdfurl: null,
    shipInfo: {
      Tonnage: "78,309",
      guestCapacity: "1,936",
      cruiseInaugural: "2021",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "906",
    },
  },
  {
    shipid: 20,
    name: "Pride of America",
    urlName: "pride-of-america",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/ship/norwegian-cruise-line-pride-of-america?ship=56",
    details:
      "Aloha! Come aboard Pride of America, our only cruise ship that sails round trip from Honolulu year-round so you can holiday on your timetable . As you journey from one exotic island to the next, learn about the Hawaiian culture through the eyes of native ambassadors on board our ship. Island hop Hawaii in style, with a wide variety of restaurants and bars and lounges, excellent family accommodations, spacious suites and balconies – perfect for whale watching, witnessing Kilauea Volcano or taking in the dramatic views of the lush natural landscape. Come aboard and learn why no one does Hawai'i like Norwegian.",
    imagepath: `${BASE_URL}/img/all_ships/norwegianPofALarge.jpeg`,
    imagepathWide: `${BASE_URL}/img/all_ships/pofA_wide.jpg`,
    videopath: "",
    pdfurl: null,
    shipInfo: {
      Tonnage: "80,439",
      guestCapacity: "2,186",
      cruiseInaugural: "2022",
      cruiseInauguralText: "Refurbished",
      length: "-",
      height: "-",
      guestCabins: "-",
      numberofDecks: "-",
      crew: "927",
    },
  },
];
