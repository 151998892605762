import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import {
  DestinationList,
  destinationSlides,
} from "../../DataStore/destinationList";
import "react-awesome-slider/dist/styles.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import { HiArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { FiSearch } from "react-icons/fi";
import { useState } from "react";
import empty from "../../assets/img/others/empty.png";
import { TfiLayoutLineSolid } from "react-icons/tfi";

function AllDestinations() {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredDestinations = DestinationList?.filter((item) =>
    item.to.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const AllDestinationsScript = `
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": "All Destinations",
    "description": "Explore our cruise destinations with a variety of travel experiences.",
    "itemListElement": [
      ${DestinationList.map((destination, index) => {
        return `
          {
            "@type": "ListItem",
            "position": ${index + 1},
            "item": {
              "@type": "TouristDestination",
              "name": "${destination.to}",
              "url": "${destination.moreInfo}",
              "touristType": "leisure",
              "offers": {
                "@type": "Offer",
                "price": "${destination.price}",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock"
              }
            }
          }
        `;
      }).join(",\n")}
    ]
  }
`;

  // Custom Previous Button component
  const CustomPrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="default"
      shape="circle"
      icon={<IoIosArrowBack className="font-black mh-32" />}
      className="custom-prev-button"
      onClick={onClick}
    />
  );

  // Custom Next Button component
  const CustomNextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="default"
      shape="circle"
      icon={<IoIosArrowForward className="font-black mh-32" />}
      className="custom-next-button"
      onClick={onClick}
    />
  );

  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevButton onClick={() => {}} />,
    nextArrow: <CustomNextButton onClick={() => {}} />,
    centerMode: true,
    centerPadding: "5%",
    autoplay: true,
  };

  return (
    <div className="destinations">
      <Helmet>
        <title>Norwegian Cruises - Cruise Destinations</title>
        <meta
          name="description"
          content="Explore your favourite destinations with Norwegian Cruises"
        />
        <link rel="canonical" href="https://www.ncl-me.com/all-destinations" />
        <script type="application/ld+json">{AllDestinationsScript}</script>
        <meta property="og:title" content="Norwegian Cruises Destinations" />
        <meta
          property="og:image"
          content="https://www.ncl-me.com/destination.jpg"
        />
        <meta
          property="og:description"
          content="Explore the best cruise destinations with NCL, Supported by our Middle East agent/agency based in Dubai,UAE"
        />
        <meta
          property="og:url"
          content="https://www.ncl-me.com/all-destinations"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="breadcrumb-div full-height">
        <div
          className="full-height flex center-start"
          style={{ marginLeft: 32 }}
        >
          <h1 className="font-faded-black basic-texts font-regular">
            <Link to="/" className="black-link">
              Home
            </Link>{" "}
            &nbsp;<TfiLayoutLineSolid/>&nbsp; Destinations
          </h1>
        </div>
      </div>
      <div className="custom-carousel-container mt-8">
        <Slider {...settings}>
          {destinationSlides?.map((item: any, index: number) => (
            <div key={index}>
              <div
                className="overlay-container-destinations pointer"
                onClick={() => {
                  window.open(`${item?.moreInfo}`, "_self");
                }}
              >
                <img
                  src={item?.imagepath}
                  alt={item?.name + " destination slide"}
                  className="image-slide"
                />
                <div className="overlay-content-destinations flex start-center p-24 flex-column">
                  <h3 className="font-white large-heading font-bold ">
                    {item?.name}
                    <div className="destinations-custom-divider mb-8" />
                  </h3>
                  <h5 className="font-white description-text font-regular">
                    More About {item?.name}{" "}
                    <HiArrowLongRight style={{ fontSize: "32px" }} />
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="bg-white column-padding-h column-padding-b all-dest-padding-t">
        <Container fluid>
          <div className="flex flex-column mb-32 ">
            <Fade duration={2000} triggerOnce>
              {" "}
              <h2 className="bold-700 sub-heading font-medium">
                Find Your Ideal Escape
              </h2>
            </Fade>
            <p className="mt-16 justified description-text font-regular">
              Embark on a voyage of discovery with Norwegian Cruise Line and
              explore some of the world's most captivating destinations. From
              the majestic landscapes of Alaska to the tropical paradise of
              Hawai`i, our cruises offer an unparalleled experience. Traverse
              the enchanting Mediterranean with our award-winning Bermuda
              itineraries or set sail on extraordinary transatlantic journeys,
              gallivanting like a true globetrotter. Immerse yourself in the
              diverse cultures, breathtaking beauty, and incredible wildlife of
              Africa. With Norwegian Cruise Line, each destination becomes a
              unique and unforgettable chapter in your travel story. Wherever
              your wanderlust leads, we invite you to make it a paradise with
              Norwegian.
            </p>
          </div>
          <div className="full-width flex center">
            <Input
              size="large"
              placeholder="Search Destinations"
              prefix={<FiSearch style={{ marginRight: "10px" }} />}
              className="mb-32"
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ width: "80%", borderRadius: "0px" }}
            />
          </div>
          <Row>
            {filteredDestinations?.length > 0 ? (
              filteredDestinations?.map((item: any, key: number) => {
                return (
                  <Col
                    sm={12}
                    md={6}
                    lg={3}
                    className="mb-42 pointer"
                    key={key}
                    onClick={() => {
                      window.open(`${item?.moreInfo}`, "_self");
                    }}
                  >
                    <div className="">
                      <div className="destination-popular-image">
                        <img src={item?.imagepath} alt="Destinations" />
                      </div>

                      <h3 className="description-text font-regular bold-600 mt-16">
                        {item?.to}
                      </h3>
                      <h4 className="basic-texts font-regular bold-600 mt-16">
                        {item?.daycount}
                      </h4>
                      <h4 className="basic-texts font-regular bold-600 mt-16">
                        Price starting from{" "}
                        <span className="font-primary">USD {item?.price}</span>
                      </h4>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className="flex center flex-column">
                <img src={empty} alt="empty-result" height={100} />
                <h4 className="description-text font-faded-black">
                  No Result found..!
                </h4>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AllDestinations;
