import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./styles.scss";
import { ShipList } from "../../DataStore/shipList";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function Ships() {
  const navigate = useNavigate();

  // Custom Previous Button component
  const CustomPrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={<IoIosArrowBack className="font-black mh-32" />}
      className="custom-prev-button-cmp"
      onClick={onClick}
    />
  );

  // Custom Next Button component
  const CustomNextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
      type="primary"
      shape="circle"
      icon={<IoIosArrowForward className="font-black mh-32" />}
      className="custom-next-button-cmp"
      onClick={onClick}
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: false,
    prevArrow: <CustomPrevButton onClick={() => {}} />,
    nextArrow: <CustomNextButton onClick={() => {}} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex column-padding-h column-padding-b column-padding-t secondary-bg ship">
      <Container fluid>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 className="sub-heading font-medium bold-800 mb-16">Our Ships</h2>
          <p className="description-text font-medium font-faded-black mb-32">
            Sail through our diverse fleet.
          </p>
        </div>
        <Row>
          <Col className="mb-32">
            <Slider {...settings}>
              {ShipList?.map((item: any) => {
                return (
                  <div
                    className="pointer"
                    onClick={() => {
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 100);
                      navigate(`/ship-details/${item?.urlName}`);
                    }}
                  >
                    <div
                      className="slider-child-ships"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={item?.imagepath}
                        alt="ship"
                        style={{ width: "100%", height: "auto" }}
                      />
                      <div
                        style={{ position: "absolute", bottom: 0 }}
                        className="full-width"
                      >
                        <div className="p-24 flex-column ship-info-div ">
                          <h3 className="ship-name bold-600 font-white description-text font-regular">
                            {item?.name}
                            <div
                              className="ships-custom-divider mb-16"
                              style={{ marginTop: "8px" }}
                            />
                          </h3>
                          <Row className="mt-16">
                            <Col className="flex center flex-column">
                              <h5 className="font-14 font-white opacity80">
                                Guests
                              </h5>
                              <h5 className="font-14 font-white opacity">
                                {item?.shipInfo?.guestCapacity}
                              </h5>
                            </Col>
                            <Col className="flex center flex-column">
                              <h5 className="font-14 font-white opacity80">
                                Tonnage
                              </h5>
                              <h5 className="font-14 font-white opacity">
                                {item?.shipInfo?.Tonnage}
                              </h5>
                            </Col>
                            <Col className="flex center flex-column">
                              <h5 className="font-14 font-white opacity80">
                                {item?.shipInfo?.cruiseInauguralText}
                              </h5>
                              <h5 className="font-14 font-white opacity">
                                {item?.shipInfo?.cruiseInaugural}
                              </h5>
                            </Col>
                          </Row>
                          {/* 
                        <h3 className="font-white basic-texts font-regular bold-500">
                          Learn More <FaLongArrowAltRight className="mh-8" />
                        </h3> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
        <div className="flex start">
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 100);
              navigate("/all-ships");
            }}
            className="card-button btn-primary btn-small basic-texts font-regular font-white ml-3"
          >
            View All Ships
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default Ships;
