import { Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";
import { BrochureList } from "../../DataStore/brochureList";

function Brochures() {
  return (
    <div className="brochures column-padding-h column-padding-b column-padding-t">
      <Container fluid>
        <Fade duration={2000} triggerOnce>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2 className="sub-heading font-medium bold-800 mb-16">
              Brochures
            </h2>
            <p className="basic-texts font-regular font-faded-black bold-600 mb-42">
              Explore Our Information Guides
            </p>
          </div>
        </Fade>
        <Row>
          {BrochureList?.map((item: any) => {
            return (
              <Col sm={12} md={6} lg={4} className="mb-42 flex center">
                <img
                  src={item?.imagepath}
                  className="pointer"
                  alt="brochure"
                  onClick={() => {
                    window.open(item?.url, "_blank");
                  }}
                  style={{ borderRadius: "12px", height: "400px" }}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Brochures;
