import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import { Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { ShipList } from "../../DataStore/shipList";
import { IoIosArrowDown } from "react-icons/io";
import ReactPlayer from "react-player";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { GoArrowRight } from "react-icons/go";
import crew from "../../assets/icons/crew.png";
import carpentry from "../../assets/icons/carpentry.png";
import capacity from "../../assets/icons/capacity.png";
import weightImg from "../../assets/icons/weight.png";
import noVideo from "../../assets/icons/no-video.png";
import { TfiLayoutLineSolid } from "react-icons/tfi";

function ShipDetails(props: any) {
  const { urlName } = useParams();

  const shipData = ShipList?.filter((item: any) => {
    return item.urlName === urlName;
  });

  const scrollToNext = (n: number) => {
    window.scroll({
      top: n * window.innerHeight - 0.05 * window.innerHeight,
    });
  };

  return (
    <div className="ship-details">
      <div className="ships-intro-common" style={{ position: "relative" }}>
        <img
          src={
            window.innerWidth <= 554
              ? shipData[0]?.imagepath
              : shipData[0]?.imagepathWide
          }
          className="ship-image-large"
          alt="ship"
        />
        <div
          className="breadcrumb-div full-width"
          style={{ padding: "0px 22px", position: "absolute", top: 0 }}
        >
          <div className="full-height flex start">
            <div className="font-faded-black basic-texts bold-500">
              <Link to="/" className="black-link">
                Home
              </Link>{" "}
              &nbsp;
              <TfiLayoutLineSolid />
              &nbsp;
              <Link to="/all-ships" className="black-link">
                Our Ships
              </Link>{" "}
              &nbsp;
              <TfiLayoutLineSolid />
              &nbsp; {shipData[0]?.name}
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title>{`${shipData[0]?.name}`}</title>
        <meta
          name="description"
          content={`Explore detailed information about ${shipData[0]?.name}. Find deck plans, ship facts, and book your cruise adventure.`}
        />
        <link
          rel="canonical"
          href={`https://www.ncl-me.com/ship-details/${shipData[0]?.urlName}`}
        />
        <meta
          property="og:title"
          content={`${shipData[0]?.name} - Norwegian Ship Information`}
        />
        <meta
          property="og:image"
          content="https://www.ncl-me.com/ships.jpg"
        />
        <meta
          property="og:description"
          content={`Explore detailed information about ${shipData[0]?.name}. Find deck plans, ship facts, and book your cruise adventure.`}
        />
        <meta
          property="og:url"
          content={`https://www.ncl-me.com/ship-details/${shipData[0]?.urlName}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="ship-info-container flex center">
        <Container fluid className="full-height">
          <Row style={{ marginTop: 16 }}>
            <Col xs={6} md={3} className="flex flex-column center mb-16">
              <img src={carpentry} className="info-icon" alt="" />
              <h4 className="basic-texts font-regular">
                {shipData[0]?.shipInfo?.cruiseInaugural}
              </h4>
              <h4 className="details-text font-medium text-align-center">
                {shipData[0]?.shipInfo?.cruiseInauguralText}
              </h4>
            </Col>
            <Col xs={6} md={3} className="flex flex-column center mb-16">
              <img src={crew} className="info-icon" alt="" />
              <h4 className="basic-texts font-regular">
                {shipData[0]?.shipInfo?.crew}
              </h4>
              <h4 className="details-text font-medium text-align-center">
                Cabin Crew
              </h4>
            </Col>
            <Col xs={6} md={3} className="flex flex-column center mb-16">
              <img src={capacity} className="info-icon" alt="" />
              <h4 className="basic-texts font-regular">
                {shipData[0]?.shipInfo?.guestCapacity}
              </h4>
              <h4 className="details-text font-medium text-align-center">
                Guest Capacity
              </h4>
            </Col>
            <Col xs={6} md={3} className="flex flex-column center mb-16">
              <img src={weightImg} className="info-icon" alt="" />
              <h4 className="basic-texts font-regular">
                {shipData[0]?.shipInfo?.Tonnage}
              </h4>
              <h4 className="details-text font-medium text-align-center">
                Tonnage
              </h4>
            </Col>
            <div className="flex center">
              <Button
                type="primary"
                shape="circle"
                icon={<IoIosArrowDown className="font-white mh-32" />}
                className="bg-primary mb-16 scroll-more-btn"
                onClick={() => {
                  scrollToNext(1);
                }}
              />
            </div>
          </Row>
        </Container>
      </div>

      <div className="column-padding-h column-padding-b column-padding-t secondary-bg">
        <Container fluid>
          <Row>
            <div className="flex flex-column">
              <Fade duration={2000} triggerOnce>
                <h2 className="bold-700 sub-heading font-medium mb-42">
                  About {shipData[0]?.name}
                </h2>
              </Fade>
            </div>
            <Col md={12} lg={6}>
              {shipData[0]?.videopath ? (
                <ReactPlayer
                  muted
                  url={shipData[0]?.videopath}
                  controls={true}
                  width={"100%"}
                  height={
                    window.innerWidth <= 554
                      ? "250px"
                      : window.innerWidth <= 768
                      ? "300px"
                      : "400px"
                  }
                  config={{
                    youtube: {
                      playerVars: { modestbranding: 1, rel: 0 },
                    },
                  }}
                  playsinline
                />
              ) : (
                <div className="flex center full-height flex-column">
                  <img
                    src={noVideo}
                    style={{ height: "50px" }}
                    className="mb-16"
                    alt=""
                  />
                  <h4 className="basic-texts font-medium font-faded-black">
                    No video available at the moment..!
                  </h4>
                </div>
              )}
            </Col>
            <Col md={12} lg={6} className="flex p-24 flex-column">
              <p className="bold-400 description-text font-regular font-black">
                {shipData[0]?.details}
              </p>
              <p
                className="basic-texts font-regular font-primary bold-600 pointer"
                onClick={() => window.open(`${shipData[0]?.moreInfo}`, "_self")}
                rel="noopener noreferrer"
                aria-label="Book Now Button"
              >
                Explore More
                <GoArrowRight className="ml-3 basic-texts font-regular" />
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ShipDetails;
