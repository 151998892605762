import BASE_URL from "../Config/apiConfig";

export const BrochureList = [
    {
        imagepath:`${BASE_URL}/img/brochures/brochure1.png`,
        url:"https://ncl-me.com/downloads/brochure1.pdf"
    },
    {
        imagepath:`${BASE_URL}/img/brochures/brochure2.png`,
        url:"https://ncl-me.com/downloads/brochure3.pdf"
    },
    {
        imagepath:`${BASE_URL}/img/brochures/brochure3.png`,
        url:"https://ncl-me.com/downloads/brochure1.pdf"
    }
];
