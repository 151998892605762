import { Button } from "antd";
import "./styles.scss";
import { Fade } from "react-awesome-reveal";

function BookNow() {
  return (
    <div className="booking column-padding-h column-padding-b column-padding-t">
      <div className="flex center flex-column">
        <Fade duration={2000} triggerOnce>
          <h2 className="bold-700 sub-heading font-white font-medium mb-16 text-align-center">
            Find Your Vacation Now
          </h2>
          <p className="font-white font-regular description-text opacity-80">
            Embark on your dream getaway , discover , plan and find your perfect
            vacation now with Norwegian Cruise Line. Your adventure awaits!
          </p>
        </Fade>
      </div>
      <div className="flex center">
        <Button
          type="primary"
          // shape="round"
          size="large"
          className="card-button btn-white btn-small flex center basic-texts font-medium font-black bg-white"
          onClick={() =>
            window.open(
              "https://book.ncl-me.com/swift/cruise?destinations=&durations=&departureports=",
              "_self"
            )
          }
          rel="noopener noreferrer"
          aria-label="Book Now Button"
        >
          Plan a Cruise
        </Button>
      </div>
    </div>
  );
}

export default BookNow;
