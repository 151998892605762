import BASE_URL from "../Config/apiConfig";

export const destinationSlides = [
  {
    imagepath: `${BASE_URL}/img/destination_slides/Med_slide.jpg`,
    imagepathSmall: `${BASE_URL}/img/destination_slides/Med_slide.jpg`,
    name: "Mediterranean",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/mediterranean?destinations=75&destinationtype=All&destinationorriverid=destinationId",
  },
  {
    imagepath: `${BASE_URL}/img/destination_slides/North_Europe.jpg`,
    imagepathSmall: `${BASE_URL}/img/destination_slides/North_Europe.jpg`,
    name: "Northern Europe",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/northern-europe?destinations=39&destinationtype=All&destinationorriverid=destinationId",
  },
  {
    imagepath: `${BASE_URL}/img/destination_slides/Alaska_slide.jpg`,
    imagepathSmall: `${BASE_URL}/img/destination_slides/Alaska_slide.jpg`,
    name: "Alaska",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/alaska-?destinations=1&destinationtype=All&destinationorriverid=destinationId",
  },
  {
    imagepath: `${BASE_URL}/img/destination_slides/Caribbean_slide.jpg`,
    imagepathSmall: `${BASE_URL}/img/destination_slides/Caribbean_slide.jpg`,
    name: "Caribbean",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/caribbean?destinations=9&destinationtype=All&destinationorriverid=destinationId",
  },
];
export const DestinationList = [
  {
    price: "781",
    to: "Mediterranean",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/mediterranean?destinations=75&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/mediterranean.webp`,
  },
  {
    price: "699",
    to: "Northern Europe",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/northern-europe?destinations=39&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/northern-europe.webp`,
  },
  {
    price: "229",
    to: "Alaska",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/alaska-?destinations=1&destinationtype=All&destinationorriverid=destinationId",
    departFrom: "Barcelona",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/Alaska.jpg`,
  },
  {
    price: "99",
    to: "Caribbean",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/caribbean?destinations=9&destinationtype=All&destinationorriverid=destinationId",
    departFrom: "Fort Lauderdale",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/caribbean.webp`,
  },
  {
    price: "747",
    to: "Africa",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/africa?destinations=2&destinationtype=All&destinationorriverid=destinationId",
    departFrom: "Fort Lauderdale",
    daycount: "7 nights",
    imagepath: `${BASE_URL}/img/destinations/africa.jpg`,
  },
  {
    price: "999",
    to: "Asia and Asia Pacific",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/asia-and-asia-pacific?destinations=19&destinationtype=All&destinationorriverid=destinationId",
    departFrom: "Fort Lauderdale",
    daycount: "7-21 nights",
    imagepath: `${BASE_URL}/img/destinations/asia.jpg`,
  },
  {
    price: "2,099",
    to: "Australia / Newzealand",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/australia-new-zealand?destinations=29&destinationtype=All&destinationorriverid=destinationId",
    departFrom: "Fort Lauderdale",
    daycount: "7-21 nights",
    imagepath: `${BASE_URL}/img/destinations/australia-newz.webp`,
  },
  {
    price: "199",
    to: "Bahamas",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/bahamas?destinations=7&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/bahamas.webp`,
  },
  {
    price: "869",
    to: "Baltic Sea (Europe)",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/baltic-sea-europe-?destinations=142&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/baltic.jpg`,
  },
  {
    price: "459",
    to: "Bermuda",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/bermuda?destinations=8&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/bermuda.webp`,
  },
  {
    price: "1,549",
    to: "British Isles",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/british-isles?destinations=121&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/british-isles.jpg`,
  },
  {
    price: "499",
    to: "Canada / New England",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/canada-new-england?destinations=26&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/canada.webp`,
  },
  {
    price: "1,069",
    to: "Canary Islands",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/canary-islands?destinations=68&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/canary.jpg`,
  },
  {
    price: "499",
    to: "Caribbean Eastern",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/caribbean-eastern?destinations=10&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/caribbean-eastern.jpg`,
  },
  {
    price: "829",
    to: "Caribbean Southern",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/caribbean-southern?destinations=13&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/caribbean-southern.jpg`,
  },
  {
    price: "429",
    to: "Caribbean Western",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/caribbean-western?destinations=14&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/caribbean-western.jpg`,
  },
  {
    price: "977",
    to: "Eastern Mediterranean",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/eastern-mediterranean?destinations=16&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/eastern-med.jpg`,
  },
  {
    price: "539",
    to: "Europe",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/europe?destinations=15&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/europe.jpg`,
  },
  {
    price: "899",
    to: "Hawaii",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/hawaii?destinations=21&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/Hawaii.webp`,
  },
  {
    price: "1,149",
    to: "Japan",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/japan?destinations=124&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/japan.jpg`,
  },
  {
    price: "449",
    to: "Mexican Riviera",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/mexican-riviera?destinations=56&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/mexican-riv.jpg`,
  },
  {
    price: "699",
    to: "Mexico",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/mexico?destinations=24&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/mexico.webp`,
  },
  {
    price: "999",
    to: "Middle East",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/middle-east?destinations=31&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/middle_east.jpg`,
  },
  {
    price: "679",
    to: "Norway",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/norway?destinations=188&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/norway.jpg`,
  },
  {
    price: "369",
    to: "Pacific",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/pacific?destinations=45&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/pacific.webp`,
  },
  {
    price: "569",
    to: "Panama Canal",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/panama-canal?destinations=49&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/panamalcanal.webp`,
  },
  {
    price: "649",
    to: "Repositioning",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/repositioning?destinations=52&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/repos.jpg`,
  },
  {
    price: "599",
    to: "South America",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/south-america?destinations=32&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/south-america.webp`,
  },
  {
    price: "749",
    to: "South Pacific",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/south-pacific?destinations=44&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/south-pacific.webp`,
  },
  {
    price: "1,149",
    to: "Southeast Asia",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/southeast-asia?destinations=130&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/southeast-asia.jpg`,
  },
  {
    price: "799",
    to: "Southern Europe",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/southern-europe?destinations=40&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/southern-europe.jpg`,
  },
  {
    price: "649",
    to: "Transatlantic",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/transatlantic?destinations=34&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/transatlantic.webp`,
  },
  {
    price: "849",
    to: "Western Europe",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/western-europe?destinations=41&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/western_europe.jpg `,
  },
  {
    price: "781",
    to: "Western Mediterranean",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/western-mediterranean?destinations=18&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/western_med.jpg`,
  },
  {
    price: "229",
    to: "North America",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/north-america?destinations=48&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/north_america.jpg`,
  },
  {
    price: "449",
    to: "Central America",
    moreInfo:
      "https://book.ncl-me.com/swift/cruise/destination/central-america?destinations=42&destinationtype=All&destinationorriverid=destinationId",
    daycount: "7-14 nights",
    imagepath: `${BASE_URL}/img/destinations/central_america.jpg`,
  },
];
