import "./styles.scss";
import { Button } from "antd";
import "./styles.scss";
import Destinations from "../../Components/Destinations";
import Ships from "../../Components/Ships";
import Registraion from "../../Components/Registration";
import BookNow from "../../Components/BookNow";
import { Helmet } from "react-helmet";
import { IoIosArrowDown } from "react-icons/io";
import BASE_URL from "../../Config/apiConfig";
import Brochures from "../../Components/Brochures";

function Home(props: any) {
  const scrollToNext = (n: number) => {
    window.scroll({
      top: n * window.innerHeight - 0.055 * window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="home">
        <Helmet>
          <title>Norwegian Cruise Line Middle East</title>
          <meta
            name="description"
            content="Explore the best cruise destinations with NCL, Supported by our Middle East agent/agency based in Dubai,UAE"
          />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Norwegian Cruise Line",
              "description": "Discover amazing cruise experiences with Norwegian Cruise Line in the World."
            }
          `}
          </script>
          <link rel="canonical" href="url" />
          <meta property="og:title" content="Norwegian Cruise Line" />
          <meta property="og:image" content="url" />
          <meta
            property="og:description"
            content="Explore the best cruise destinations with NCL, Supported by our Middle East agent/agency based in Dubai,UAE"
          />
          <meta property="og:url" content="url" />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className={"main"}>
          <video
            src={`${BASE_URL}/videos/intro_video.mp4`}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className={"content"}>
            <div
              className="full-height flex flex-column full-width column-padding-h"
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* center-end */}
              {/* <h1 className="font-regular mb-16">Peace of the place</h1> */}
              <h1 className="font-bold large-heading mb-32 text-align-center">
                Cruise with Luxury, Discover with Style
              </h1>
              <Button
                type="primary"
                size="large"
                className="card-button btn-primary btn-large flex center basic-texts font-medium font-white bg-primary mb-32"
                onClick={() => {
                  window.open("https://book.ncl-me.com/swift/cruise?destinations=&durations=&departureports=", "_self");
                }}
              >
                Plan a Cruise
              </Button>
            </div>
            <Button
              type="primary"
              shape="circle"
              icon={<IoIosArrowDown className="font-black mh-32" />}
              className="bg-white mb-32"
              onClick={() => {
                scrollToNext(1);
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-white">
        <Destinations />
        <Ships />
        <Brochures/>
        <BookNow />
        <Registraion />
      </div>
    </>
  );
}

export default Home;
