import { Container } from "react-bootstrap";
import "./styles.scss";
import { Button, Form } from "antd";
import { Input } from "antd";
import { notification } from "antd";
import { Fade } from "react-awesome-reveal";

type NotificationType = "success" | "info" | "warning" | "error";

function Registraion() {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Welcome Aboard!",
      description:
        "Thank you for joining our cruise community! Prepare to receive exclusive booking alerts and exciting offers. Welcome aboard!",
    });
  };

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "Invalid Email!",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = (values: any) => {
    openNotificationWithIcon("success");
    form.resetFields();
  };

  const [form] = Form.useForm();

  return (
    <div className="registration column-padding-h column-padding-b column-padding-t">
      {contextHolder}
      <Container>
        <div className="flex center flex-column">
          <Fade duration={2000} triggerOnce>
            <h2 className="bold-700 sub-heading font-medium mb-16 text-align-center">
              Join Our Community
            </h2>
          </Fade>
        </div>
        <Form
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          form={form}
          className="flex center"
        >
          <div className="flex center full-width input-div">
            <Form.Item
              name={["User", "email"]}
              rules={[{ type: "email", required: true }]}
              className="full-width"
            >
              <Input
                placeholder="Your Email"
                className="bold-500"
                style={{ borderRadius: 0 }}
              />
            </Form.Item>
            <Form.Item className="flex center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="card-button btn-primary btn-small basic-texts font-regular font-white ml-12"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Container>
    </div>
  );
}

export default Registraion;
